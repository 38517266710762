<script lang="ts" setup>
const emit = defineEmits<{ (e: 'close'): void }>()
</script>

<template>
    <div class="feedback-form-success feedback-form-error">
        <SvgoError />
        <h4 class="feedback-form-success__title">
            {{ $t('feedbackForm.msgErrorTitle') }}
        </h4>
        <p class="feedback-form-success__descr">
            {{ $t('feedbackForm.msgErrorDesc') }}
        </p>

        <button
            class="feedback-form-success__btn-close"
            @click="emit('close')"
        >
            {{ $t('feedbackForm.tryAgain') }}
        </button>
    </div>
</template>

<style lang="scss" scoped></style>
